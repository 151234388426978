<template>
<div>
  <template >
<!--    height="100vh"-->
<!--    style="@media only screen and (max-width: 768px){height:100vh;}"-->
    <v-carousel :show-arrows="false"  hide-delimiter-background  cycle  height="100vh">
      <v-carousel-item
        src="@/assets//images/sliders/slide_one.jpg"
      >
        <template>
          <v-row  align="center" justify="center">
            <v-col>
              <v-row style="  margin-top:10%; margin: 15%;">
                <v-col cols="12" lg="12">
                  <h1 class="slide_title" style="color:#fff;">Bienvenue sur la plateforme de la cité Sainte Augustine</h1><br>
                  <p class="slide_content">Passez des séjours inoubliables</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-carousel-item>
      <v-carousel-item
        src="@/assets//images/sliders/slide_three.jpg"
      >
        <v-row  align="center" justify="center">
          <v-row style="margin-top:10%; margin: 15%;">
            <v-col cols="12" lg="12">
              <h1 class="slide_title" style="color:#fff;">Passez de belles vacances</h1><br>
              <p class="slide_content">oublier le stress</p>
            </v-col>
          </v-row>
        </v-row>
<!--        <v-sheet color="transparent" height="auto" tile>-->
<!--        </v-sheet>-->
      </v-carousel-item>
      <v-carousel-item
        src="@/assets/images/sliders/slide_two.jpg"
      >
        <v-row  align="center" justify="center">
          <v-row style="margin-top:10%; margin: 15%;">
            <v-col cols="12" lg="12">
              <h1 class="slide_title" style="color:#fff;">Le Meilleur pour les meilleurs</h1><br>
              <p class="slide_content">parce que vous être special , nous vous donnons les apparts de qualité pour vos rendez-vous d'affaires</p>
            </v-col>
          </v-row>
        </v-row>
<!--        <v-sheet color="transparent" height="100%" tile>-->
<!--        </v-sheet>-->
      </v-carousel-item>
    </v-carousel>
  </template>
</div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Landing',
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.carrousel_content{
  position: relative;
  display: flex;
  justify-content:center;
  align-items: center;
}
.slide_title{
  font-size:25px;
  text-transform:uppercase;
  letter-spacing:2px;
  align-items: center;
  font-family:"JetBrains Mono Light",sans-serif;
}
.slide_content{
  width:60%;
  font-family:"JetBrains Mono Light",sans-serif;
  color:#fff;
  align-items: center;
}
</style>
